$white-bg-1: #ffffff;
$black-color: #000000;
$background-color: #cedfe0;
$primary-background-color: #16828b;
$table-cell-color: #f5fafa;
$font-color: #292929;
$app-bg-color: #f5f5f5;
$gray-color: #858585;
$hyper-link-color: #1890ff;
$help-icon-color: #a5a5a5;
$auth-button-color: #10c6aa;
$valid-password-color: #85dd5a;
$selected-menu-item-color: #ddeef0;
$menu-item-color: #666666;
$down-arrow-chart-bg: #d2eff8;
$up-arrow-chart-bg: #ffd3d3;
$down-arrow-chart-text: #c31413;
$up-arrow-chart-text: #3b99b6;
$input-border: #bac4c5;
$form-input-slider: #03dac6;
$direction-icon-bg: #ddeef0b3;
$floating-icon-bg: #26a69ab3;
$card-header-bg: #d9d9d9;
$emergency-btn-bg: #c42525;
$input-validation-border-color: #ff4d4f;
$box-shadow-color: #00000026;
$table-btn-box-shadow-color: #00000059;
$up-graph-tr-bg-color: #f5f9fa;
$down-graph-tr-bg-color: #faf7f6;
$active-tab-color: #f18f48;
$tab-color: #64a790;
$table-border: #f2f2f2;
$project-tab-linear-gradient: linear-gradient(
  269.91deg,
  #d8e7e2 2.88%,
  rgba(216, 231, 226, 0) 83.6%
);
$advance-filter-bg-color: #e2eceb;
$advance-active-tab-color: #26a69a;
$active-dept-arrow-color: #81a1a3;
$active-navigation-tab-color: rgb(36, 132, 100);
$summary-text-color: #616868;
$not-active-filter-tab-color: #c4c4c4;
