@import './variable.scss';

@mixin commonButton {
  background-color: $primary-background-color;
  border: none;
  border-radius: 4px;
  color: $white-bg-1;
  font-size: 14px;
  font-weight: 500;
}

@mixin borderedButton {
  background-color: $white-bg-1;
  border: 1px solid $primary-background-color;
  border-radius: 4px;
  color: $primary-background-color;
  font-size: 14px;
  font-weight: 500;
}

@mixin commonTable {
  th {
    font-size: 16px;
    white-space: nowrap;
    font-weight: 700;
    background-color: $white-bg-1;
    padding: 10px 10px;
    border: none;
  }
  th:before {
    content: none !important;
  }
  td {
    padding: 10px 10px;
    font-size: 16px;
    font-weight: 400;
    border: none;
    white-space: nowrap;
    border-bottom: 1px solid $table-border !important;
  }
  tbody {
    tr {
      &:nth-child(odd) {
        td {
          background-color: $table-border;
        }
      }
      &:nth-child(even) {
        td {
          background-color: $white-bg-1;
        }
      }
    }
  }
}

@mixin filterFormButtons {
  text-align: center;
  .clear-filter-btn {
    @include borderedButton;
    margin-right: 12px;
  }
  .apply-filter-btn {
    @include commonButton;
  }
}

@mixin commonModal {
  border-radius: 4px;
  .ant-modal-header {
    padding: 16px 0;
    border-radius: 4px;
    .ant-modal-title {
      margin-left: 25px;
      font-size: 19px;
      font-weight: 400;
    }
  }
  .ant-modal-body {
    padding: 0;
    max-height: 590px;
    min-height: auto;
    overflow: auto;
  }
  .ant-modal-footer {
    padding: 20px;
    .footer-main-button {
      @include commonButton;
    }
    .footer-cancel-button {
      @include borderedButton;
    }
  }
}

@mixin commonTableWithGraph {
  overflow-x: auto;
  .ant-table-cell-fix-left,
  .ant-table-cell-fix-right {
    z-index: 0;
  }
  .ant-table-ping-right {
    .ant-table-cell-fix-right-first::after {
      box-shadow: inset -12px 0 8px -8px $box-shadow-color;
    }
  }
  .ant-table-container {
    overflow: auto;
    table {
      @include commonTable;
      tr {
        td {
          .highcharts-container {
            svg {
              .highcharts-plot-background {
                width: 105px;
                height: 50px;
                x: 0;
                y: 0;
              }
              image {
                width: 40px;
                height: 40px;
                x: 30;
                y: 5;
              }
            }
          }
          .kpi-amount-table-column-wrapper {
            display: flex;
            flex-direction: column;
            text-align: center;
            .kpi-target {
              font-size: 24px;
              font-weight: 600;
              sub {
                bottom: 0;
              }
            }
            .kpi-performance {
              font-size: 13px;
              font-weight: 700;
              sub {
                bottom: 0;
              }
            }
          }
          .budget-amount-table-column-wrapper {
            span {
              font-size: 20px;
              font-weight: 600;
            }
          }
          .pj-comment-col {
            display: inline-block;
            width: 480px;
            white-space: pre-wrap;
          }
          .kpi-name-col {
            width: 200px;
          }
        }
        .ant-table-cell-fix-right {
          text-align: center;
          .project-open-btn {
            width: 80px;
            box-shadow: 0px 4px 4px $table-btn-box-shadow-color;
            @include commonButton;
          }
          .group-pj-btn {
            background-color: #504f4f;
            color: $white-bg-1;
            border: 1px solid #504f4f;
          }
          .group-pj-remove-btn {
            background-color: #fdffff;
            color: #9ea2a2;
            border: 1px solid #9ea2a2;
          }
          .group-pj-add-btn {
            background-color: $white-bg-1;
            color: $primary-background-color;
            border: 1px solid $primary-background-color;
          }
        }
      }
      .up-graph {
        td {
          background-color: $up-graph-tr-bg-color !important;
          .kpi-performance {
            color: #4692fa !important;
          }
        }
      }
      .down-graph {
        td {
          background-color: $down-graph-tr-bg-color !important;
          .kpi-performance {
            color: #f37935 !important;
          }
        }
      }
      .straight-graph {
        td {
          background-color: $white-bg-1 !important;
          .kpi-performance {
            color: #818181 !important;
          }
        }
      }
    }
  }
}

@mixin checkboxCommon {
  align-items: center !important;
  .ant-checkbox-inner {
    border-color: $input-border !important;
    border-radius: 50% !important;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-checkbox-inner:after {
    left: 28.5%;
    border-color: $input-border !important;
    opacity: 1 !important;
    transform: rotate(45deg) scale(1) translate(-50%, -50%);
    height: 7px;
    width: 4px;
  }

  .ant-checkbox-checked {
    .ant-checkbox-inner {
      border-color: $primary-background-color !important;
      background-color: $primary-background-color !important;
    }
    .ant-checkbox-inner:after {
      border-color: $white-bg-1 !important;
      left: 28.5%;
      transform: rotate(45deg) scale(1) translate(-50%, -50%);
      opacity: 1;
      height: 7px;
      width: 4px;
    }
  }
}

@mixin commonCascaderCheckbox {
  .ant-cascader-checkbox {
    margin-top: 3px;
  }
  .ant-cascader-checkbox-inner {
    border-color: $input-border !important;
    border-radius: 50% !important;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 18px;
    width: 18px;
    border-width: 2px;
    &:after {
      left: 29%;
      border-color: $input-border !important;
      opacity: 1 !important;
      transform: rotate(45deg) scale(1) translate(-50%, -50%);
      height: 7px;
      width: 4px;
    }
  }

  .ant-cascader-checkbox-checked {
    .ant-cascader-checkbox-inner {
      border-color: $primary-background-color !important;
      background-color: $primary-background-color !important;
      &:after {
        border-color: $white-bg-1 !important;
      }
    }
  }

  .ant-cascader-checkbox-indeterminate {
    .ant-cascader-checkbox-inner {
      border-color: $primary-background-color !important;
      background-color: $primary-background-color !important;
      &:after {
        background-color: $white-bg-1;
        height: 6px;
        width: 2px;
        top: 36%;
        left: 20%;
        transform: rotate(90deg) scale(1) translate(-50%, -50%);
      }
    }
  }
}

@mixin indeterminateCheckbox {
  height: 2px !important;
  width: 6px !important;
  background-color: $white-bg-1;
  left: 50% !important;
  transform: translate(-50%, -50%) scale(1) !important;
}

@mixin commonAdministratorTabs {
  .ant-tabs-nav-wrap {
    padding-top: 10px;
    justify-content: flex-end;
    margin-right: 20px;
    .ant-tabs-nav-list {
      .ant-tabs-tab {
        background-color: $white-bg-1;
        border-radius: 8px 8px 0 0;
        font-size: 14px;
        font-weight: 700;
        box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.25);
        margin-right: 10px;
        color: $black-color;
        min-width: 165px;
        min-height: 50px;
        .ant-tabs-tab-btn {
          width: 100%;
          text-align: center;
        }
      }
      .ant-tabs-tab-active {
        .ant-tabs-tab-btn {
          color: $black-color;
          &[id*='work'] {
            border-left: 3px solid #f37935;
          }
          &[id*='project-administrator'] {
            border-left: 3px solid $primary-background-color;
          }
        }
      }
    }
  }
}

@mixin inputSuffixCommon {
  border-left: 1px solid $input-border;
  .comment-btn {
    font-size: 20px;
    cursor: pointer;
    padding: 10px;
    height: 100%;
    display: flex;
    align-items: center;
    svg {
      fill: $menu-item-color;
    }
  }
}

@mixin commonCustomPagination {
  border-color: transparent;
  background: transparent;
  a {
    color: $black-color;
  }
}
